.gridCol .gridColInner {
  text-align: center;
  transform: scale(0.9);
  transition: all 0.2s ease-in-out;
}
.gridCol .gridColInner img {
  border: 4px solid #373b3e;
  border-radius: 100%;
  margin-bottom: var(--size-20px);
  transition: all 0.2s ease-in-out;
}
.gridCol .gridColInner .text {
  color: var(--color-text);
  font-size: var(--size-22px);
  transition: all 0.2s ease-in-out;
}
.gridCol .gridColInner:hover {
  transform: scale(1);
}
.gridCol:hover .gridColInner img {
  border-color: var(--color-link);
}
.gridCol:hover .gridColInner .text {
  color: var(--color-link);
}
@media (max-width: 767px) {
  .gridCol .gridColInner .text {
    font-size: var(--size-40px);
  }
}
@media (max-width: 1280px) {
  .ear1-9 {
    max-width: 80% !important;
  }
}
