@media (max-width: 767px) {
  .raXs {
    width: 30px;
    margin-left: 10px;
    margin-right: 10px;
  }
  .nose-proc h1 {
    margin-bottom: var(--size-100px);
  }
  .nose-proc img {
    display: block;
    margin-left: auto;
    margin-right: auto;
    max-width: 60%;
  }
}

.nose-after .d-flex {
  padding-left: var(--size-50px);
  padding-right: var(--size-50px);
}
